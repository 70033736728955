#aboutContainer {
    min-height : 95vh;
    background-color : #1A1A1D;
    margin: 0px;
    color: white;
    padding-bottom: 15vh;
}

.pad {
    padding-left: 0;
}

@media screen and (max-width: 500px){
    .pad{
        padding-left: 3vh;
        padding-right: 3vh;
    }
}

.yellow {
    color: #FEE715FF
}

.logo {
    margin-right: 3vh;
    fill : white;
    width : 5vh;
    height : 5vh;
    opacity : 0.5;
}

.logo:hover {
    opacity : 1;
    fill: #FEE715FF;
}

.download { 
    color: inherit;
    text-decoration: underline;
}

.download:hover {
     color:#FEE715FF; 
     cursor:pointer;  
}
.white-text {
    color:#808080 !important;
    margin-left: 4vh;
}

.white-text:hover {
    color:white !important;
    cursor: pointer;
}

#brand:hover {
    animation: zoominoutsinglefeatured 2s infinite ;
    cursor: pointer;
}

@keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.02,1.02);
    }
    100% {
        transform: scale(1,1);
    }
}
.white-nav {
    background-color: white;
}

.black-nav {
    background-color: #1A1A1D;
}


.jumbotron {
    margin-top: 10vh;
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh; /* These two lines are counted as one :-)       */
}

.intro {
    padding-top: 35vh;
    color: white;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

@media screen and (max-width: 1200px){
    .pro-gallery{
        margin-top:20vh;
    }
    
}

@media screen and (max-width: 850px){
    .pro-gallery{
        margin-top:30vh;
    }
}

@media screen and (max-width: 599px){
    .pro-gallery{
        margin-top:20vh;
    }
    #particlesBG {
        padding-top:30vh;
    }
}

.introText:hover{
    animation: zoominoutsinglefeatured 1s infinite;
    
}

#introContainer {
    min-height: 100vh;
    width: 100%;
    left: 0;
    right: 0;
    margin-left: 0;
    margin-right: 0;
}

#hello {
    animation: zoominoutsinglefeatured 1s infinite ;
}

@keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.05,1.05);
    }
    100% {
        transform: scale(1,1);
    }
}
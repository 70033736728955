.filter {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    
  }

.transparency {
    background-color: transparent;
    border: transparent;
}

*:focus {
    outline: 0 !important;
}
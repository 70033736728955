#pageContainer {
    min-height : 100vh;
    background-color : #1A1A1D;
    margin: 0px;
    color: white;
    padding-top: 10vh;
    padding-bottom: 10vh;
}

#bodyContainer {
    margin-left: auto;
    margin-right: auto;
    width: 65%;
}

.yellow {
    color: #FEE715FF
}

.logo {
    margin-right: 3vh;
    fill : white;
    width : 4vh;
    height : 4vh;
    opacity : 0.5;
    margin-bottom: 3vh;
}

.logo:hover {
    opacity : 1;
    fill: #FEE715FF;
}

.top {
    opacity : 0.5;
}

.top:hover {
    color:#FEE715FF !important;
    cursor: pointer;
    opacity : 1;
}

p {
    font-size: 1.15rem;
}

.subtitle {
    margin-top:3vh;
    margin-bottom:3vh;
}